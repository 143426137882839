import { unref } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { TableOptions } from './table-options';

export default function useOptionsParser(
  _options?: MaybeRef<TableOptions>
) {
  const options = unref(_options ?? {}) as TableOptions;
  
  const { page, itemsPerPage, sortBy, sortDesc } = options;
  const isSorted = sortBy?.length
  let sortQuery = ``

  if (isSorted) {
    sortQuery = `&sort=`
    sortDesc.map((isDescending, index) => {
      if (index > 0) sortQuery += ','

      const key = sortBy[index]
      let prefix = isDescending ? '-' : ''
      sortQuery += prefix + key
    })
  }

  let result = `?page=${page}&limit=${itemsPerPage}${sortQuery}`

  return result;
}

export { useOptionsParser };
