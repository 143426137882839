import { unref } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';

export default function useSearchParser(
  _search?: MaybeRef<string>,
  prefix?: string
) {
  const search = unref(_search ?? '');

  if (search) return encodeURI(`${prefix ?? ''}q=${String(search).trim()}`);

  return '';
}

export { useSearchParser };
