import useErrorMessageHandler from '@/@core/helpers/errorMessageHandler';
import useOptionsParser from '@/@core/helpers/options-parser';
import useSearchParser from '@/@core/helpers/search-parser';
import { TableOptions } from '@/@core/helpers/table-options';
import { useCallApi } from '@/@core/services/axiosCall';
import {
  reactive,
  Ref,
  ref,
  toRefs,
  unref
} from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { defineStore } from 'pinia';

export const useClassificationStore = defineStore(
  'classification',
  () => {
    const state = reactive({
      total: 0,
      classifications: []
    });

    async function getManyClassification(
      options: MaybeRef<TableOptions>,
      search?: MaybeRef<string>
    ) {
      const optionsQuery = useOptionsParser(options);
      const searchQuery = useSearchParser(search, '&');
      const query = `${optionsQuery}${searchQuery}`;

      const { errorMessage, result, error } = await useCallApi({
        method: 'get',
        url: `/classifications${query}`
      });

      if (errorMessage) {
        useErrorMessageHandler(error);

        return null;
      }

      state.classifications = result.classifications;
      state.total = result.count;

      return result;
    }

    async function postClassification(data: any) {
      const { errorMessage, result, error } = await useCallApi({
        method: 'post',
        url: '/classifications',
        data: unref(data)
      });

      if (errorMessage) {
        useErrorMessageHandler(error);

        return null;
      }

      return result;
    }

    async function putClassification(data: any) {
      const { errorMessage, result, error } = await useCallApi({
        method: 'put',
        url: '/classifications/' + data.id,
        data: unref(data)
      });

      if (errorMessage) {
        useErrorMessageHandler(error);

        return null;
      }

      return result;
    }

    async function deleteClassification(data: any) {
      const { errorMessage, result, error } = await useCallApi({
        method: 'delete',
        url: '/classifications/' + data.id
      });

      if (errorMessage) {
        useErrorMessageHandler(error);

        return null;
      }

      return result;
    }

    async function importClassification(data: any) {
      const { errorMessage, result, error } = await useCallApi({
        method: 'post',
        url: '/classifications/import',
        data: unref(data)
      });

      if (errorMessage) {
        useErrorMessageHandler(error);

        return null;
      }

      return result;
    }

    return {
      ...toRefs(state),
      getManyClassification,
      postClassification,
      deleteClassification,
      putClassification,
      importClassification
    };
  }
);
